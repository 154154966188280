import React, { useCallback, useEffect, useState } from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import axios from 'axios'
import InfiniteScroll from 'react-infinite-scroll-component'
import Masonry from 'react-masonry-css'
import ArtItem from '../components/ArtItem'
import API_URL from '../utilities/APIUrl'

export default function () {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState(null)
  const [categoriesLoading, setCategoriesLoading] = useState(true)
  const [categories, setCategories] = useState(null)

  const breakpointColumns = {
    default: 4,
    1100: 3,
    700: 2,
  }

  const [artItems, setArtItems] = useState([])
  const [hasMore, setHasMore] = useState(true)

  const fetchMoreArt = useCallback(async () => {
    console.log(artItems)
    const result = await axios.post(`${API_URL}graphql`, {
      query: `{
        posts(first: 10${
          artItems.length
            ? ', after: "' + artItems[artItems.length - 1].cursor + '"'
            : ''
        }){
          edges {
            node {
              id
              slug
              uri
              title
              featuredImage {
                node {
                  sizes
                  srcSet
                  mediaDetails {
                    height
                    width
                  }
                }
              }
            }
            cursor
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
      `,
    })
    setArtItems((prev) => {
      return prev.concat(result.data.data.posts.edges)
    })
    if (result.data.data.posts.pageInfo.hasNextPage) {
      setHasMore(result.data.data.posts.pageInfo.hasNextPage)
    }
  }, [artItems])

  useEffect(() => {
    fetchMoreArt()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let mounted = true

    const loadCategories = async () => {
      const result = await axios.post(`${API_URL}graphql`, {
        query: `
        categories {
          edges {
            node {
              id
              name
              count
            }
          }
        }`,
      })

      setCategories(result)
      setCategoriesLoading(false)
    }

    const loadArtItems = async () => {
      const result = await axios.post(`${API_URL}graphql`, {
        query: `
        query wordpressPosts{
          posts {
            edges {
              node {
                id
                slug
                uri
                title
                featuredImage {
                  node {
                    sizes
                    srcSet
                    mediaDetails {
                      height
                      width
                    }
                  }
                }
              }
            }
          }
        }
        `,
      })
      setData(result)
      setIsLoading(false)
      return result
    }

    if (mounted) {
      loadArtItems()
    }
    return () => (mounted = false)
  }, [])
  return (
    <Layout>
      <SEO keywords={[`galleri`]} title="Galleri" />
      {!isLoading ? (
        <section className="text-center max-w-6xl px-4 py-8 mx-auto md:px-8 md:py-16">
          <div>
            <h2 className="font-bold lg:text-xl mb-2 uppercase text-left">
              Filtrera
            </h2>
          </div>

          <div className={'flex flex-wrap justify-center -mx-2 mb-4'}>
            <InfiniteScroll
              dataLength={artItems.length} //This is an important field to render the next data
              next={fetchMoreArt}
              hasMore={hasMore}
              loader={<h4>Laddar...</h4>}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Du har nu sett alla konstverk!</b>
                </p>
              }
            >
              <Masonry
                breakpointCols={breakpointColumns}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {artItems.map((item, index) => (
                  <ArtItem key={index} data={item} />
                ))}
              </Masonry>
            </InfiniteScroll>
          </div>
        </section>
      ) : (
        'Det laddar'
      )}
    </Layout>
  )
}
